import { 
    Box, 
    Table, 
    TableRow, 
    TableBody, 
    TableCell, 
    TableHead, 
    Typography,  
    TableContainer
 } from "@mui/material";
import MySkeleton from "../components/Skeleton";
import { adminPanel } from "../utils/muiStyle";

const MatchesMainPageLoader = () => {
    const classs = adminPanel();
    return (
        <Box className={classs.mainBody}>
            <TableContainer>
                <Table width="100%">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3} width="50%" align="left">
                                <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                    <Typography>
                                        <MySkeleton width={150} />
                                    </Typography>
                                    {/* <MySkeleton variant='rounded' width={290} height={40} /> */}
                                </Box>
                            </TableCell>
                            {/* <TableCell colSpan={3} width="50%" className="py-0">
                                <Box className='last-cell-table-Loader'>
                                    <MySkeleton variant='rounded' height={33} width={86} />
                                </Box>
                            </TableCell> */}
                        </TableRow>
                        <TableRow>
                            {[...Array(5).keys()].map((_, index) => ( 
                                <TableCell>
                                    <MySkeleton width={120} />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Typography className='last-cell-table-Loader'>
                                    <MySkeleton width={70} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(10).keys()].map((_, index) => ( 
                            <TableRow key={index}>
                                {[...Array(2).keys()].map((_, index) => ( 
                                    <TableCell>
                                        <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                            <MySkeleton variant='rounded' height={34} width={34}/>
                                            <MySkeleton width={150}/>
                                        </Box>    
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <MySkeleton variant='rounded' height={24} width={65}/>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={150} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                                <TableCell>
                                    <Typography className='last-cell-table-Loader'>
                                        <MySkeleton variant="rounded" height={15} width={3.5}/>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default MatchesMainPageLoader
